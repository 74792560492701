<template>
  <div class="sidebar__item">             
    <div class="sidebar__item--title">
      <h3>{{ $t('products.categories') }} </h3>
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 5L7.5 12L1 5" stroke="#1D1929" stroke-linecap="square"/>
      </svg>
    </div>
    <div class="sidebar__item--content">  
      <div class="item__category">
        <label class="item__form--category">{{ $t('products.general') }}</label>
        
        <b-form-radio-group
          name="cats"          
          stacked                                                                                     
          :options="bestSellerCategory" 
          
          @change="getProductsByCategory"
          :disabled="isLoadingProducts"
          v-model="selectedCategory.idCategory"                          
        />         
      </div>
      <div class="item__category" v-for="(category, index) in categories.withSubCategories" :key="index">
        <label class="item__form--category">{{category.text}}</label>
        <b-form-radio-group
          name="cats"          
          stacked                                                                                     
          :options="category.subcategory"                                   
          @change="getProductsBySubCategory"
          :disabled="isLoadingProducts"
          v-model="selectedCategory.idSubCategory"                          
        />  
      </div>          
    </div>
    </div>
</template>

<script>
  import { mapState, mapActions,  mapMutations } from 'vuex'
  import { market } from '@/modules/shop/mixins/market'
  import { dataLayer } from '@/modules/shop/mixins/dataLayer'  

  export default {
    mixins: [market, dataLayer],
          
    computed: {
      ...mapState('auth',['queryParams','lang']),               
      ...mapState('start',['categories','selectedCategory']),
      ...mapState('products',['isLoadingProducts']),                                          
      bestSellerCategory(){
        return this.categories.onlyCategories.length > 0 ? [this.categories.onlyCategories[0]] : []
      }
    },
    methods:{         
      ...mapActions('products',['fetchProductsRate','fetchBestSellerProducts','fetchProductsByCategories']),    
      ...mapMutations('products',['setProducts','setLoadingProducts']),                     
      ...mapMutations('start',['setSelectedCategory']),
      ...mapMutations('auth',['setSubCategoryQueryParam','setCategoryQueryParam']), 
      async getProductsByCategory( idCategory ){      
        let products = []      
        this.setSelectedCategory({idCategory, idSubCategory: null})  
        this.setCategoryQueryParam(idCategory)
        this.calculateBreakdownInCart()
        this.$router.replace({ query: {...this.queryParams} }).catch(() => {}) 
        this.setLoadingProducts( true)       
                  
        const payload = {            
            adults: parseInt(this.queryParams.adults),
            children: parseInt(this.queryParams.children),
            date: this.queryParams.date,                        
            idCategory : null,
            idSubCategory : this.selectedCategory.idSubCategory || null,
            iddest: this.queryParams.destination ? parseInt(this.queryParams.destination) : null,
            language: this.lang && this.lang === 'en' ? 1 :  2
          }      
      
     
          products = await this.fetchProductsRate( payload )         
         
        
        this.setProducts( products )
        this.setLoadingProducts( false )   
        this.$emit('set-to-page-one')    
        this.$emit('collapse-categories')           
      },            
      async getProductsBySubCategory(idSubCategory ){      
        let products = []                   
        this.setSelectedCategory({idCategory: null, idSubCategory,  })  
        this.setSubCategoryQueryParam(idSubCategory)
        this.calculateBreakdownInCart() //desde el mixin market
        this.$router.replace({ query: {...this.queryParams} }).catch(() => {})  
        this.setLoadingProducts( true)  
        const payload  = {            
            adults: parseInt(this.queryParams.adults),
            children: parseInt(this.queryParams.children),
            date: this.queryParams.date,                        
            idCategory : null,
            idSubCategory : this.selectedCategory.idSubCategory || null,
            iddest: this.queryParams.destination ? parseInt(this.queryParams.destination) : null,
            language: this.lang && this.lang === 'en' ? 1 :  2
          }      
      
        products = await this.fetchProductsRate( payload )        
        this.setProducts( products )
        this.setLoadingProducts( false)   
        this.$emit('set-to-page-one')   
        this.$emit('collapse-categories')           
      },
    }
    
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/filter-category.scss";  
</style>
